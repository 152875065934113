<template>
  <v-container fluid>
    <div class="d-flex flex-wrap justify-space-between">
      <div class="graph text-right" id="div1">
        <v-skeleton-loader type="image" v-if="loading"></v-skeleton-loader>
        <canvas v-show="!loading" id="graph1"></canvas>
        <v-icon @click="extend(1,$event.target)">mdi-fullscreen</v-icon>
      </div>
      <div class="graph text-right" id="div4">
        <v-skeleton-loader type="image" v-if="loading"></v-skeleton-loader>
        <canvas v-show="!loading" id="graph4"></canvas>
        <v-icon @click="extend(4,$event.target)">mdi-fullscreen</v-icon>
      </div>
      <div class="graph text-right" id="div2">
        <v-skeleton-loader type="image" v-if="loading"></v-skeleton-loader>
        <canvas v-show="!loading" id="graph2"></canvas>
        <v-icon @click="extend(2,$event.target)">mdi-fullscreen</v-icon>
      </div>
      <div class="graph text-right" id="div8">
        <v-skeleton-loader type="image" v-if="loading"></v-skeleton-loader>
        <canvas v-show="!loading" id="graph8"></canvas>
        <v-icon @click="extend(8,$event.target)">mdi-fullscreen</v-icon>
      </div>
      <div class="graph text-right" id="div3">
        <v-skeleton-loader type="image" v-if="loading"></v-skeleton-loader>
        <canvas v-show="!loading" id="graph3"></canvas>
        <v-icon @click="extend(3,$event.target)">mdi-fullscreen</v-icon>
      </div>
      <div class="graph text-right" id="div7">
        <v-skeleton-loader type="image" v-if="loading"></v-skeleton-loader>
        <canvas v-show="!loading" id="graph7"></canvas>
        <v-icon @click="extend(7,$event.target)">mdi-fullscreen</v-icon>
      </div>
      <div class="graph text-right" id="div5">
        <v-skeleton-loader type="image" v-if="loading"></v-skeleton-loader>
        <canvas v-show="!loading" id="graph5"></canvas>
        <v-icon @click="extend(5,$event.target)">mdi-fullscreen</v-icon>
      </div>
      <div class="graph text-right" id="div9">
        <v-skeleton-loader type="image" v-if="loading"></v-skeleton-loader>
        <canvas v-show="!loading" id="graph9"></canvas>
        <v-icon @click="extend(9,$event.target)">mdi-fullscreen</v-icon>
      </div>
      <div class="graph text-right" id="div6">
        <v-skeleton-loader type="image" v-if="loading"></v-skeleton-loader>
        <canvas v-show="!loading" id="graph6"></canvas>
        <v-icon @click="extend(6,$event.target)">mdi-fullscreen</v-icon>
      </div>
    </div>
  </v-container>
</template>

<script>
import { parseDate } from "@/utils";
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';


export default {
  data() {
    return {
      loading: null,
      resumen: [
        {
          fecha : 2015,
          bodas : 83,
          nAdultos : 11400,
          nNinos : 845,
          total : 12245,
          avAdultos : 137,
          piscis : 24,
          hotel : 27,
          lalanne : 20,
          laus : 0,
          toz : 4,
          palacio : 0,
          oAragon : 8,
          oCat : 0,
          nsnc : 0,
          negativa : 0,
          neutra : 0,
          positiva : 0,
          bodasnetVal : 0,
          eCivil : 17,
          eReligioso : 66,
          jardin : 34,
          terraza : 7,
          objetivo : 90,
          pntObjetivo : 92,
          exito : 75,
          criticas : 14,
          pnt100 : 10.84,
          pntMonzon : 61,
          pntBodegas : 29,
          pntCat : 10,
          monzon : 30,
          cinca : 17,
          somontano : 24,
          litera : 7,
          oHuesca : 12,
          oFuera : 10,
          experiencia : 64,
          recomendacion : 18,
          rrss : 1,
          bodasnet : 6,
          otraReserva : 1,
          perdidas:28,
        },
        {
          fecha : 2016,
          bodas : 84,
          nAdultos : 11550,
          nNinos : 850,
          total : 12400,
          avAdultos : 138,
          piscis : 24,
          hotel : 20,
          lalanne : 26,
          laus : 0,
          toz : 5,
          palacio : 0,
          oAragon : 9,
          oCat : 0,
          nsnc : 0,
          negativa : 0,
          neutra : 0,
          positiva : 0,
          bodasnetVal : 0,
          eCivil : 21,
          eReligioso : 63,
          jardin : 29,
          terraza : 8,
          objetivo : 90,
          pntObjetivo : 93,
          exito : 76,
          criticas : 17,
          pnt100 : 14.29,
          pntMonzon : 52,
          pntBodegas : 37,
          pntCat : 11,
          monzon : 25,
          cinca : 15,
          somontano : 32,
          litera : 10,
          oHuesca : 8,
          oFuera : 10,
          experiencia : 63,
          recomendacion : 20,
          rrss : 0,
          bodasnet : 8,
          otraReserva : 2,
          perdidas:27
        },
        {
          fecha :2017,
          bodas :76,
          nAdultos :10860,
          nNinos :800,
          total :11660,
          avAdultos :143,
          piscis :16,
          hotel :14,
          lalanne :27,
          laus :0,
          toz :6,
          palacio :0,
          oAragon :13,
          oCat :0,
          nsnc : 0,
          negativa : 0,
          neutra : 0,
          positiva : 0,
          bodasnetVal : 0,
          eCivil :21,
          eReligioso :55,
          jardin :17,
          terraza :10,
          objetivo :90,
          pntObjetivo :84,
          exito :69,
          criticas :19,
          pnt100 : 25.00,
          pntMonzon :39,
          pntBodegas :43,
          pntCat :17,
          monzon :29,
          cinca :11,
          somontano :32,
          litera :9,
          oHuesca :3,
          oFuera :17,
          experiencia :74,
          recomendacion :17,
          rrss :0,
          bodasnet :5,
          otraReserva :1,
          perdidas:34
        },
        {
          fecha : 2018,
          bodas : 81,
          nAdultos : 12240,
          nNinos : 846,
          total : 13086,
          avAdultos : 162,
          piscis : 20,
          hotel : 16,
          lalanne : 27,
          laus : 0,
          toz : 7,
          palacio : 0,
          oAragon : 9,
          oCat : 2,
          nsnc : 0,
          negativa : 0,
          neutra : 0,
          positiva : 0,
          bodasnetVal : 0,
          eCivil : 20,
          eReligioso : 61,
          jardin : 24,
          terraza : 6,
          objetivo : 90,
          pntObjetivo : 90,
          exito : 73,
          criticas : 17,
          pnt100 : 13.58,
          pntMonzon : 44,
          pntBodegas : 42,
          pntCat : 14,
          monzon : 30,
          cinca : 10,
          somontano : 28,
          litera : 10,
          oHuesca : 11,
          oFuera : 12,
          experiencia : 63,
          recomendacion : 16,
          rrss : 0,
          bodasnet : 18,
          otraReserva : 3,
          perdidas:30
        },
        {
          fecha : 2019,
          bodas : 72,
          nAdultos : 9326,
          nNinos : 735,
          total : 10061,
          avAdultos : 140,
          piscis : 14,
          hotel : 16,
          lalanne : 19,
          laus : 5,
          toz : 4,
          palacio : 1,
          oAragon : 9,
          oCat : 4,
          nsnc : 0,
          negativa : 0,
          neutra : 0,
          positiva : 0,
          bodasnetVal : 0,
          eCivil : 27,
          eReligioso : 45,
          jardin : 17,
          terraza : 4,
          objetivo : 90,
          pntObjetivo : 80,
          exito : 65,
          criticas : 16,
          pnt100 : 36.11,
          pntMonzon : 42,
          pntBodegas : 40,
          pntCat : 18,
          monzon : 45,
          cinca : 9,
          somontano : 13,
          litera : 7,
          oHuesca : 6,
          oFuera : 19,
          experiencia : 63,
          recomendacion : 15,
          rrss : 0,
          bodasnet : 22,
          otraReserva : 0,
          perdidas:39
        },
        // {
        //   fecha : 2020,
        //   bodas : 8,
        //   nAdultos : 784,
        //   nNinos : 39,
        //   total : 823,
        //   avAdultos : 103,
        //   piscis : 0,
        //   hotel : 3,
        //   lalanne : 3,
        //   laus : 2,
        //   toz : 0,
        //   palacio : 0,
        //   oAragon : 0,
        //   oCat : 0,
        //   nsnc : 0,
        //   negativa : 0,
        //   neutra : 0,
        //   positiva : 0,
        //   bodasnetVal : 0,
        //   eCivil : 3,
        //   eReligioso : 5,
        //   jardin : 0,
        //   terraza : 0,
        //   objetivo : 90,
        //   pntObjetivo : 8,
        //   exito : 7,
        //   criticas : 0,
        //   pnt100 : 75.00,
        //   pntMonzon : 38,
        //   pntBodegas : 63,
        //   pntCat : 0,
        //   monzon : 25,
        //   cinca : 13,
        //   somontano : 50,
        //   litera : 0,
        //   oHuesca : 0,
        //   oFuera : 13,
        //   experiencia : 56,
        //   recomendacion : 18,
        //   rrss : 13,
        //   bodasnet : 12,
        //   otraReserva : 1,
          // perdidas:106
        // },
        {
          fecha : 2021,
          bodas : 62,
          nAdultos : 6045,
          nNinos : 305,
          total : 6350,
          avAdultos : 102,
          piscis : 5,
          hotel : 5,
          lalanne : 21,
          laus : 13,
          toz : 4,
          palacio : 0,
          oAragon : 11,
          oCat : 3,
          nsnc : 0,
          negativa : 0,
          neutra : 0,
          positiva : 0,
          bodasnetVal : 0,
          eCivil : 17,
          eReligioso : 45,
          jardin : 5,
          terraza : 2,
          objetivo : 70,
          pntObjetivo : 68,
          exito : 89,
          criticas : 11,
          pnt100 : 14.52,
          pntMonzon : 16,
          pntBodegas : 61,
          pntCat : 23,
          monzon : 23,
          cinca : 4,
          somontano : 29,
          litera : 6,
          oHuesca : 10,
          oFuera : 23,
          experiencia : 58,
          recomendacion : 22,
          rrss : 9,
          bodasnet : 9,
          otraReserva : 2,
          perdidas:41
        },
        {
          fecha : 2022,
          bodas : 95,
          nAdultos : 13035,
          nNinos : 799,
          total : 13834,
          avAdultos : 146,
          piscis : 12,
          hotel : 10,
          lalanne : 28,
          laus : 20,
          toz : 2,
          palacio : 0,
          oAragon : 22,
          oCat : 1,
          nsnc : 0,
          negativa : 0,
          neutra : 0,
          positiva : 0,
          bodasnetVal : 0,
          eCivil : 14,
          eReligioso : 81,
          jardin : 16,
          terraza : 6,
          objetivo : 100,
          pntObjetivo : 95,
          exito : 95,
          criticas : 19,
          pnt100 : 13.68,
          pntMonzon : 23,
          pntBodegas : 53,
          pntCat : 24,
          monzon : 23,
          cinca : 9,
          somontano : 18,
          litera : 2,
          oHuesca : 12,
          oFuera : 26,
          experiencia : 57,
          recomendacion : 20,
          rrss : 12,
          bodasnet : 4,
          otraReserva : 8,
          perdidas:38
        }

      ],
      chart1: {},
      chart2: {},
      chart3: {},
      chart4: {},
      chart5: {},
      chart6: {},
      chart7: {},
      chart8: {},
      chart9: {},
      specialColorBorder:{
        success: 'rgba(76, 175, 80, 1)',
        info: 'rgba(54, 162, 235, 1)',
        warning: 'rgba(251, 140, 0, 1)',
        error: 'rgba(255, 82, 82, 1)'
      },
      specialColor:{
        success: 'rgba(76, 175, 80, 0.2)',
        info: 'rgba(54, 162, 235, 0.2)',
        warning: 'rgba(251, 140, 0, 0.2)',
        error: 'rgba(255, 82, 82, 0.2)'
      },
      borderColors:[
        'rgba(54, 162, 235, 1)',
        'rgba(181, 20, 66, 1)',
        'rgba(255, 159, 64, 1)',
        'rgba(255, 205, 86, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(0, 0, 207, 1)',
        'rgba(64, 255, 88, 1)',
        'rgba(12, 112, 12, 1)',
        'rgba(255, 87, 230, 1)'
      ],
      colors:[
        'rgba(54, 162, 235, 0.2)',
        'rgba(181, 20, 66, 0.2)',
        'rgba(255, 159, 64, 0.2)',
        'rgba(255, 205, 86, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(0, 0, 207, 0.2)',
        'rgba(64, 255, 88, 0.2)',
        'rgba(12, 112, 12, 0.2)',
        'rgba(255, 87, 230,0.2)'
      ]
    };
  },
  computed:{
    resumenComp(){
      if (new Date(new Date().getFullYear(), 4,1,12,0,0) < new Date()) return this.resumen.filter(r => r.fecha > 2016 && r.fecha <= new Date().getFullYear()+1)
      else return this.resumen.filter(r => r.fecha > 2016 && r.fecha <= new Date().getFullYear())
    },
  },
  mounted(){
    this.loading = true
    this.getBodas()
  },
  methods: {
    extend(e,t){
      if (t.getAttribute("class").split(" ").includes("mdi-fullscreen")) {
        t.setAttribute("class", t.getAttribute("class").replace("mdi-fullscreen", "mdi-fullscreen-exit"))
        document.getElementById("div" + e).setAttribute("class",document.getElementById("div" + e).getAttribute("class").replace("graph", "graphExtended"));
      }
      else{
        t.setAttribute("class", t.getAttribute("class").replace("mdi-fullscreen-exit", "mdi-fullscreen"))
        document.getElementById("div" + e).setAttribute("class",document.getElementById("div" + e).getAttribute("class").replace("graphExtended", "graph"));  
      }
    },  
    parseDate,
    async getBodas() {
      const resumen = await axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_URL}/listado/bodatotal`,
      });

      this.loading = false

      this.resumen = [...this.resumen,...resumen.data];

      this.resumen.forEach(y => {
        if (y.fecha == "2023") {
          y.perdidas += 37
        }
      });

      const self = this

      this.chart1 = new Chart(document.getElementById("graph1"), {
        type: 'line',
        data: {
          labels: [null,...this.resumenComp.map(r => r.fecha),null],
          datasets: [
            {
              label: 'CONFIRMADAS',
              tension: 0.25,
              data: [null,...this.resumenComp.map(r => r.bodas),null],
              backgroundColor: [
                this.specialColor["success"],
              ],
              borderColor: [
                this.specialColorBorder["success"],
              ],
              fill: true,
              backgroundColor: this.specialColor["success"],
              borderWidth: 1,
              datalabels: {
                labels:{
                  index:{
                    color: this.specialColorBorder['success'],
                    anchor: 'end',
                    align: 'top',
                    clamp: true,
                    offset: -5,
                  },
                  name:{
                    color: this.specialColorBorder['success'],
                    anchor: 'end',
                    align: 'bottom',
                    clamp: true,
                    formatter: function(value, context) {
                      return Math.round(parseFloat(value / Math.max(parseInt((self.resumenComp[context.dataIndex-1]?.bodas || 0) + (self.resumenComp[context.dataIndex-1]?.perdidas || 0)),1))*100) + '%'
                    }
                  }
                },
              },
            },
            {
              label: 'PERDIDAS',
              tension: 0.25,
              data: [null,...this.resumenComp.map(r => r.perdidas),null],
              backgroundColor: [
                this.specialColor["error"],
              ],
              borderColor: [
                this.specialColorBorder["error"],
              ],
              fill: true,
              backgroundColor: this.specialColor["error"],
              borderWidth: 1,
              datalabels: {
                color: this.specialColorBorder['error'],
                anchor: 'end',
                align: 'top',
                clamp: true,
                offset: -5
              },
            }
          ]
        },
        options: {
          maintainAspectRatio: false,
          plugins: {
            title: {
              color: '#d39652',
              display: true,
              text: 'TOTAL BODAS',
              position: 'top',
              align:'center',
              font: {
                size: 24,
              }
            }
          },
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                stepSize: 5,
                display: true
              }
            },
          },
        },
        plugins: [ChartDataLabels],
      })

      this.chart2 = new Chart(document.getElementById("graph2"), {
        type: 'line',
        data: {
          labels: [null,...this.resumenComp.map(r => r.fecha),null],
          datasets: [
            {
              label: 'PIS',
              data: [null,...this.resumenComp.map(r => r.piscis),null],
              backgroundColor: [
                this.colors[0],
              ],
              borderColor: [
                this.borderColors[0],
              ],
              fill: false,
              backgroundColor: this.colors[0],
              borderWidth: 1,
              datalabels: {
                color: this.borderColors[0],
                anchor: 'end',
                align: 'top',
                clamp: true,
                offset: -5
              },
            },
            {
              label: 'HOT',
              data: [null,...this.resumenComp.map(r => r.hotel),null],
              backgroundColor: [
                this.colors[1],
              ],
              borderColor: [
                this.borderColors[1],
              ],
              fill: false,
              backgroundColor: this.colors[1],
              borderWidth: 1,
              datalabels: {
                color: this.borderColors[1],
                anchor: 'end',
                align: 'top',
                clamp: true,
                offset: -5
              },
            },
            {
              label: 'LAL',
              data: [null,...this.resumenComp.map(r => r.lalanne),null],
              backgroundColor: [
                this.colors[2],
              ],
              borderColor: [
                this.borderColors[2],
              ],
              fill: false,
              backgroundColor: this.colors[2],
              borderWidth: 1,
              datalabels: {
                color: this.borderColors[2],
                anchor: 'end',
                align: 'top',
                clamp: true,
                offset: -5
              },
            },
            {
              label: 'LAUS',
              data: [null,...this.resumenComp.map(r => r.laus),null],
              backgroundColor: [
                this.colors[3],
              ],
              borderColor: [
                this.borderColors[3],
              ],
              fill: false,
              backgroundColor: this.colors[3],
              borderWidth: 1,
              datalabels: {
                color: this.borderColors[3],
                anchor: 'end',
                align: 'top',
                clamp: true,
                offset: -5
              },
            },
            {
              label: 'MDP',
              data: [null,...this.resumenComp.map(r => r.mdp || 0),null],
              backgroundColor: [
                this.colors[7],
              ],
              borderColor: [
                this.borderColors[7],
              ],
              fill: false,
              backgroundColor: this.colors[7],
              borderWidth: 1,
              datalabels: {
                color: this.borderColors[7],
                anchor: 'end',
                align: 'top',
                clamp: true,
                offset: -5
              },
            },
            {
              label: 'PANT',
              data: [null,...this.resumenComp.map(r => r.panticosa || 0),null],
              backgroundColor: [
                this.colors[9],
              ],
              borderColor: [
                this.borderColors[9],
              ],
              fill: false,
              backgroundColor: this.colors[9],
              borderWidth: 1,
              datalabels: {
                color: this.borderColors[9],
                anchor: 'end',
                align: 'top',
                clamp: true,
                offset: -5
              },
            },
            {
              label: 'SOMMOS',
              data: [null,...this.resumenComp.map(r => r.sommos || 0),null],
              backgroundColor: [
                this.colors[8],
              ],
              borderColor: [
                this.borderColors[8],
              ],
              fill: false,
              backgroundColor: this.colors[8],
              borderWidth: 1,
              datalabels: {
                color: this.borderColors[8],
                anchor: 'end',
                align: 'top',
                clamp: true,
                offset: -5
              },
            },
            {
              label: 'TOZ&PAL',
              data: [null,...this.resumenComp.map(r => r.toz + r.palacio),null],
              backgroundColor: [
                this.colors[4],
              ],
              borderColor: [
                this.borderColors[4],
              ],
              fill: false,
              backgroundColor: this.colors[4],
              borderWidth: 1,
              datalabels: {
                color: this.borderColors[4],
                anchor: 'end',
                align: 'top',
                clamp: true,
                offset: -5
              },
            },
            {
              label: 'ARAGÓN',
              data: [null,...this.resumenComp.map(r => r.oAragon),null],
              backgroundColor: [
                this.colors[5],
              ],
              borderColor: [
                this.borderColors[5],
              ],
              fill: false,
              backgroundColor: this.colors[5],
              borderWidth: 1,
              datalabels: {
                color: this.borderColors[5],
                anchor: 'end',
                align: 'top',
                clamp: true,
                offset: -5
              },
            },
            {
              label: 'LLEIDA',
              data: [null,...this.resumenComp.map(r => r.oCat),null],
              backgroundColor: [
                this.colors[6],
              ],
              borderColor: [
                this.borderColors[6],
              ],
              fill: false,
              backgroundColor: this.colors[6],
              borderWidth: 1,
              datalabels: {
                color: this.borderColors[6],
                anchor: 'end',
                align: 'top',
                clamp: true,
                offset: -5
              },
            }
          ]
        },
        options: {
          maintainAspectRatio: false,
          plugins: {
            legend: {
              labels: {
                boxWidth: 15
              }
            },
            title: {
              color: '#d39652',
              display: true,
              text: 'ESPACIOS CELEBRACIÓN',
              position: 'top',
              align:'center',
              font: {
                size: 24,
              },
            }
          },
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                stepSize: 5,
                display: true
              }
            }
          },
        },
        plugins: [ChartDataLabels],
      })

      var ds = []
      this.resumenComp.slice(2022-this.resumenComp.map(r => r.fecha).sort().reverse()[0]).forEach((r,i) => {
        var tot = r.negativa + r.neutra + r.positiva + r.bodasnetVal
        ds.push({ 
          label : r.fecha,
          data : [Math.round((r.positiva / tot) * 100), Math.round((r.neutra / tot) * 100), Math.round((r.negativa / tot) * 100)],
          backgroundColor: this.colors[i],
          borderColor: this.borderColors[i],
          pointBackgroundColor: this.colors[i],
          pointBorderColor: this.borderColors[i],
          pointHoverBackgroundColor: this.colors[i],
          pointHoverBorderColor: this.borderColors[i],
          tooltip: {
            callbacks: {
              label: function(context) {
                return r.fecha + ': ' + context.formattedValue + '%'
              }
            }
          }
         })
      });

      this.chart3 = new Chart(document.getElementById("graph3"), {
        type: 'radar',
        data: {
          labels: ["Positiva", "Neutra", "Negativa"],
          datasets: ds
        },
        options:{
          maintainAspectRatio: false,
          plugins: {
            title: {
              color: '#d39652',
              display: true,
              text: 'VALORACIÓN (%)',
              position: 'top',
              align:'center',
              font: {
                size: 24,
              },
            }
          },
          scales:{
            r:{
              min: 0,
              max: 100,
              beginAtZero: true,
              ticks:{
                backdropColor: 'rgba(0,0,0,0)',
                callback: (value) => `${value}     `,
                color: '#d39652',
              },
              // angleLines:{
              //   color: this.angleLines
              // },
              // grid:{
              //   color: this.grid
              // }
            }
          }
        },
        plugins: [ChartDataLabels],
      })

      this.chart4 = new Chart(document.getElementById("graph4"), {
        data: {
          plugins: [ChartDataLabels],
          labels: [null,...this.resumenComp.map(r => r.fecha),null],
          datasets: [
            {
              type:'bar',
              label: 'ADULTOS',
              tension: 0,
              data: [null,...this.resumenComp.map(r => r.nAdultos),null],
              backgroundColor: [
                this.specialColor["info"],
              ],
              borderColor: [
                this.specialColorBorder["info"],
              ],
              backgroundColor: this.specialColor["info"],
              borderWidth: 1,
              yAxisID: 'y',
              datalabels: {
                color: this.specialColorBorder['info'],
                align: 'end',
                anchor: 'start',
                clamp: true
              }
            },
            {
              type:'line',
              label: 'MEDIA ADULTOS',
              tension: 0,
              data: [null,...this.resumenComp.map(r => r.avAdultos),null],
              backgroundColor: [
                this.specialColor['warning'],
              ],
              borderColor: [
                this.specialColorBorder['warning'],
              ],
              backgroundColor: this.specialColor['warning'],
              borderWidth: 1,
              yAxisID: 'y2',
              datalabels: {
                color: this.specialColorBorder['warning'],
                align: 'start',
                anchor: 'start',
                clamp: true,
              }
            }
          ]
        },
        options: {
          maintainAspectRatio: false,
          plugins: {
            title: {
              color: '#d39652',
              display: true,
              text: 'TOTAL COMENSALES',
              position: 'top',
              align:'center',
              font: {
                size: 24,
              },
            }
          },
          scales: {
            y: {
              ticks: {
                color: this.specialColorBorder['info'],
                display: true
              },
              beginAtZero: true,
            },
            y2: {
              position: 'right',
              ticks: {
                color: this.specialColorBorder['warning']
              },
              grid: {
                drawOnChartArea: false // only want the grid lines for one axis to show up
              },
              beginAtZero: true,
            }
          }
        },
        plugins: [ChartDataLabels],
      })

      this.chart5 = new Chart(document.getElementById("graph5"), {
        type: 'line',
        data: {
          labels: [null,...this.resumenComp.map(r => r.fecha),null],
          datasets: [
            {
              label: 'CIVIL',
              data: [null,...this.resumenComp.map(r => r.eCivil),null],
              backgroundColor: [
                this.specialColor['info'],
              ],
              borderColor: [
                this.specialColorBorder['info'],
              ],
              fill: false,
              backgroundColor: this.specialColor['info'],
              borderWidth: 1,
              datalabels: {
                color: this.specialColorBorder['info'],
                anchor: 'end',
                align: 'top',
                clamp: true,
                offset: -5
              },
            },
            {
              label: 'RELIGIOSO',
              data: [null,...this.resumenComp.map(r => r.eReligioso),null],
              backgroundColor: [
                this.specialColor['success'],
              ],
              borderColor: [
                this.specialColorBorder['success'],
              ],
              fill: false,
              backgroundColor: this.specialColor['success'],
              borderWidth: 1,
              datalabels: {
                color: this.specialColorBorder['success'],
                anchor: 'end',
                align: 'top',
                clamp: true,
                offset: -5
              },
            }
          ]
        },
        options: {
          maintainAspectRatio: false,
          plugins:{
            title: {
              color: '#d39652',
              display: true,
              text: 'TIPOS DE ENLACES',
              align:'center',
              font: {
                size: 24,
              },
              position: 'top'
            }
          },
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                stepSize: 5,
                display: true
              }
            }
          },
        },
        plugins: [ChartDataLabels],
      })

      this.chart6 = new Chart(document.getElementById("graph6"), {
        type: 'line',
        data: {
          labels: [null,...this.resumenComp.map(r => r.fecha),null],
          datasets: [
            {
              label: 'JARDÍN',
              data: [null,...this.resumenComp.map(r => r.jardin),null],
              backgroundColor: [
                this.specialColor['info'],
              ],
              borderColor: [
                this.specialColorBorder['info'],
              ],
              fill: false,
              backgroundColor: this.specialColor['info'],
              borderWidth: 1,
              datalabels: {
                color: this.specialColorBorder['info'],
                anchor: 'end',
                align: 'top',
                clamp: true,
                offset: -5
              },
            },
            {
              label: 'TERRAZA',
              data: [null,...this.resumenComp.map(r => r.terraza),null],
              backgroundColor: [
                this.specialColor['success'],
              ],
              borderColor: [
                this.specialColorBorder['success'],
              ],
              fill: false,
              backgroundColor: this.specialColor['success'],
              borderWidth: 1,
              datalabels: {
                color: this.specialColorBorder['success'],
                anchor: 'end',
                align: 'top',
                clamp: true,
                offset: -5
              },
            }
          ]
        },
        options: {
          maintainAspectRatio: false,
          plugins:{
            title: {
              color: '#d39652',
              display: true,
              text: 'REPARTO APERITIVOS (MONZÓN)',
              position:'top',
              align:'center',
              font: {
                size: 24,
              }
            }
          },
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                stepSize: 5,
                display: true
              }
            }
          },
        },
        plugins: [ChartDataLabels],
      })

      this.chart7 = new Chart(document.getElementById("graph7"), {
        type: 'line',
        data: {
          labels: [null,...this.resumenComp.map(r => r.fecha),null],
          datasets: [
            {
              label: 'MONZÓN',
              data: [null,...this.resumenComp.map(r => Math.round(r.pntMonzon)),null],
              backgroundColor: [
                this.specialColor['info'],
              ],
              borderColor: [
                this.specialColorBorder['info'],
              ],
              fill: false,
              backgroundColor: this.specialColor['info'],
              borderWidth: 1,
              datalabels: {
                color: this.specialColorBorder['info'],
                anchor: 'end',
                align: 'top',
                clamp: true,
                offset: -5
              },
            },
            {
              label: 'OTROS ESPACIOS PROPIOS',
              data: [null,...this.resumenComp.map(r => Math.round(r.pntBodegas)),null],
              backgroundColor: [
                this.specialColor['success'],
              ],
              borderColor: [
                this.specialColorBorder['success'],
              ],
              fill: false,
              backgroundColor: this.specialColor['success'],
              borderWidth: 1,
              datalabels: {
                color: this.specialColorBorder['success'],
                anchor: 'end',
                align: 'top',
                clamp: true,
                offset: -5
              },
            },
            {
              label: 'CATERING',
              data: [null,...this.resumenComp.map(r => Math.round(r.pntCat)),null],
              backgroundColor: [
                this.specialColor['warning'],
              ],
              borderColor: [
                this.specialColorBorder['warning'],
              ],
              fill: false,
              backgroundColor: this.specialColor['warning'],
              borderWidth: 1,
              datalabels: {
                color: this.specialColorBorder['warning'],
                anchor: 'end',
                align: 'top',
                clamp: true,
                offset: -5
              },
            }
          ]
        },
        options: {
          maintainAspectRatio: false,
          plugins:{
            title: {
              color: '#d39652',
              display: true,
              text: 'REPARTO POR UBICACIÓN (%)',
              position:'top',
              align:'center',
              font: {
                size: 24,
              }
            }
          },
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                stepSize: 5,
                display: true
              }
            }
          },
        },
        plugins: [ChartDataLabels],
      })

      this.chart8 = new Chart(document.getElementById("graph8"), {
        type: 'line',
        data: {
          labels: [null,...this.resumenComp.map(r => r.fecha),null],
          datasets: [
            {
              label: 'EXPERIENCIA',
              data: [null,...this.resumenComp.map(r => Math.round(r.experiencia)),null],
              backgroundColor: [
                this.colors[0],
              ],
              borderColor: [
                this.borderColors[0],
              ],
              fill: false,
              backgroundColor: this.colors[0],
              borderWidth: 1,
              datalabels: {
                color: this.borderColors[0],
                anchor: 'end',
                align: 'top',
                clamp: true,
                offset: -5
              },
            },
            {
              label: 'RECOMENDACIÓN',
              data: [null,...this.resumenComp.map(r => Math.round(r.recomendacion)),null],
              backgroundColor: [
                this.colors[1],
              ],
              borderColor: [
                this.borderColors[1],
              ],
              fill: false,
              backgroundColor: this.colors[1],
              borderWidth: 1,
              datalabels: {
                color: this.borderColors[1],
                anchor: 'end',
                align: 'top',
                clamp: true,
                offset: -5
              },
            },
            {
              label: 'REDES SOCIALES',
              data: [null,...this.resumenComp.map(r => Math.round(r.rrss)),null],
              backgroundColor: [
                this.colors[2],
              ],
              borderColor: [
                this.borderColors[2],
              ],
              fill: false,
              backgroundColor: this.colors[2],
              borderWidth: 1,
              datalabels: {
                color: this.borderColors[2],
                anchor: 'end',
                align: 'top',
                clamp: true,
                offset: -5
              },
            },
            {
              label: 'BODAS.NET',
              data: [null,...this.resumenComp.map(r => Math.round(r.bodasnet)),null],
              backgroundColor: [
                this.colors[3],
              ],
              borderColor: [
                this.borderColors[3],
              ],
              fill: false,
              backgroundColor: this.colors[3],
              borderWidth: 1,
              datalabels: {
                color: this.borderColors[3],
                anchor: 'end',
                align: 'top',
                clamp: true,
                offset: -5
              },
            },
            {
              label: 'OTROS',
              data: [null,...this.resumenComp.map(r => Math.round(r.otraReserva)),null],
              backgroundColor: [
                this.colors[4],
              ],
              borderColor: [
                this.borderColors[4],
              ],
              fill: false,
              backgroundColor: this.colors[4],
              borderWidth: 1,
              datalabels: {
                color: this.borderColors[4],
                anchor: 'end',
                align: 'top',
                clamp: true,
                offset: -5
              },
            },
          ]
        },
        options: {
          maintainAspectRatio: false,
          plugins:{
            legend:{
              labels:{
                boxWidth: 15
              }
            },
            title: {
              color: '#d39652',
              display: true,
              text: 'ORIGEN RESERVA (%)',
              position:'top',
              align:'center',
              font: {
                size: 24,
              }
            }
          },
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                stepSize: 5,
                display: true
              }
            }
          },
        },
        plugins: [ChartDataLabels],
      })

      this.chart9 = new Chart(document.getElementById("graph9"), {
        type: 'bar',
        data: {
          labels: [null,...this.resumenComp.map(r => r.fecha),null],
          datasets: [
            {
              label: 'BODAS.NET',
              data: [null,...this.resumenComp.map(r => Math.round(r.bodasnetVal || 0)),null],
              backgroundColor: [
                this.colors[0],
              ],
              borderColor: [
                this.borderColors[0],
              ],
              fill: false,
              backgroundColor: this.colors[0],
              borderWidth: 1,
              datalabels: {
                color: this.borderColors[0],
                anchor: 'end',
                align: 'top',
                clamp: true,
                offset: -5
              },
            },
            {
              label: 'OTROS',
              data: [null,...this.resumenComp.map(r => Math.round(r.bodasnetNot || 0)),null],
              backgroundColor: [
                this.colors[1],
              ],
              borderColor: [
                this.borderColors[1],
              ],
              fill: false,
              backgroundColor: this.colors[1],
              borderWidth: 1,
              datalabels: {
                color: this.borderColors[1],
                anchor: 'end',
                align: 'top',
                clamp: true,
                offset: -5
              },
            },
          ]
        },
        options: {
          maintainAspectRatio: false,
          plugins:{
            legend:{
              labels:{
                boxWidth: 15
              }
            },
            title: {
              color: '#d39652',
              display: true,
              text: 'ORIGEN VALORACIÓN',
              position:'top',
              align:'center',
              font: {
                size: 24,
              }
            }
          },
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                stepSize: 5,
                display: true
              }
            }
          },
        },
        plugins: [ChartDataLabels],
      })
    }
  },
}
</script>

<style>
.graph {
  /* border: 1px solid var(--v-secondary-lighten5); */
  cursor: pointer;
  /* transition: all .5s ease-in-out; */
  height:400px !important; width: calc(100%/3.05) !important;
}
.graph > canvas {
  /* border: 1px solid var(--v-secondary-lighten5); */
  cursor: pointer;
  /* transition: all .5s ease-in-out; */
  max-height:350px !important; width: 100% !important;
}
.graphExtended {
  /* border: 1px solid var(--v-secondary-lighten5); */
  cursor: pointer;
  /* transition: all .5s ease-in-out; */
  height: 800px !important; width: 100% !important;
}
.graphExtended > canvas {
  /* border: 1px solid var(--v-secondary-lighten5); */
  cursor: pointer;
  /* transition: all .5s ease-in-out; */
  max-height:750px !important; width: 100% !important;
}
</style>